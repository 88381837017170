import "./i18n/config";
import {type AlertColor, Typography, Tabs, Tab, Button} from "@mui/material";
import {Component} from "react";
import GroupDetails from "./components/GroupDetails";
import GroupMembers from "./components/GroupMembers";
import { Empty } from "./components/Empty";
import {isMemberAdmin, type GroupInfo, type GroupMember} from "./types/gigya";
import {GigyaGroupModels} from "./types/gigya.schema";
import "./App.css";
import "./components/.exported_teleporthq/groups wip-react/src/style.css";
import P11440311HGMHomeAdminmemberViewgroupdetailstabclon from "./components/.exported_teleporthq/groups wip-react/src/views/p11440311h-g-m-home-adminmember-viewgroupdetailstabclon";
import {type WithTranslation, withTranslation} from "react-i18next";
import {SelectW, transformExportedDesign} from "./muiConverter";
import React from "react";
import ModalDialog from "./components/ModalDialog";
import {Buffer} from "buffer";
import {withRouter, type WithRouterProps} from "./withRouter";
import {REACT_APP_TST_REDIRECT_URL, REACT_APP_PRD_REDIRECT_URL,REACT_APP_DEV_REDIRECT_URL, EMPTY_MEMBERS} from "./constants";
import { pushDataLayerEvent } from "./utils";

const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || "dev";

const getRedirectUrl = () => {
	const TST = "tst";
	const PRD = "prd";
	if (ENVIRONMENT === TST) return REACT_APP_TST_REDIRECT_URL||"";
	if (ENVIRONMENT === PRD) return REACT_APP_PRD_REDIRECT_URL||"";
	return REACT_APP_DEV_REDIRECT_URL||"";
};

class App extends Component<WithTranslation & WithRouterProps, {
	memberClinics: Array<GroupInfo<GigyaGroupModels.ClinicModel>>;
	selectedTab: number;
	selectedClinicForm?: GroupInfo<GigyaGroupModels.ClinicModel>;
	selectedClinicIndex?: number;
	dirty: boolean;
	modalDialog: ModalDialogState;
	user: GroupMember;
}> {

	componentDidMount() {
		// Redirect the user if a session can not be found. We are not using react-router-dom
		// Here because that library only handles in app navigation and not navigation to
		// External URLs.
		window.gigya.hasSession()
			.then(sessionExist => {
				if (!sessionExist) {
					window.location.href = getRedirectUrl();
				}
			})
			.catch(err => {
				console.log(err);
			});
		
		// Get group data needed for the application
		window.gigya.accounts.groups.getAllMemberGroups({
			callback: groupsResponse => {
				if (!groupsResponse.errorCode) {
					// Get info of the logged in user. The initial groupId form the parent request is needed
					// So we are nesting this request in the callback method.
					if(groupsResponse.results.length) {
						window.gigya.accounts.groups.getGroupMemberInfo({
							model: GigyaGroupModels.ClinicModel,
							groupId: groupsResponse.results[0].groupId,
							callback: infoResponse => {
								if (infoResponse.errorCode) {
									console.log("ERROR accounts.groups.getGroupMemberInfo: ", infoResponse);
								} else {
									// The initial state of the application is set here. Structured clone does a deep copy of an object
									// And moves the reference of any nested objects to the new copy.
									this.setState({
										memberClinics: groupsResponse.results,
										selectedClinicForm: groupsResponse.results.length ? structuredClone(groupsResponse.results[0]) : undefined,
										selectedClinicIndex: groupsResponse.results.length ? 0 : undefined,
										user: infoResponse.result,
									});
								}
							},
						});
					}
					else {
						console.log("ERROR accounts.groups.getGroupMemberInfo: This user is not assigned to any group.");
						this.setState({
							memberClinics: groupsResponse.results,
							selectedClinicForm: groupsResponse.results.length ? structuredClone(groupsResponse.results[0]) : undefined,
							selectedClinicIndex: groupsResponse.results.length ? 0 : undefined,
							user: {
								UID: "",
								groupId: "",
								model: "",
								lastUpdated: "",
								lastUpdatedTimestamp: "",
								memberSince: "",
								memberSinceTimestamp: "",
								permissions: ""
							},
						});
					}
					
					if (window.location.pathname === "/invite") {
						const groupId = Buffer.from(new URLSearchParams(window.location.search).get("clinic") ?? "", "base64").toString("utf8");
						if (groupId) {
							const groupName = groupsResponse.results.find(g => g.groupId === groupId)?.groupData.clinicName as string ?? "";
							if (groupName) {
								this.setState({
									modalDialog: {
										open: true,
										style: {height: "290px"},
										title: this.props.t("exported.You’ve Been Added To A New Team!"),
										content: <Typography variant='UIObjectsText1'>{this.props.t("exported. Congrats, you’re now a part of the College Blvd Animal Hospital team! If you did not intend to join this Team, you can Leave at any time. ")}</Typography>,
										actions: [<Button key={0} variant='text' onClick={this.handleModalDialogClose}>{this.props.t("exported.Got it!")}</Button>],
									},
								});
							}
						}
					}
				}
			},
		});
	}

	updateDirty = (d: boolean) => {
		this.setState({dirty: d});
	};

	refreshData = () => {
		const {memberClinics} = this.state;
		memberClinics[this.state.selectedClinicIndex!] = structuredClone(this.state.selectedClinicForm!);
		this.setState({
			memberClinics,
			dirty: false,
		});
	};

	handleModalDialogClose = () => {
		this.setState({modalDialog: {open: false}});
	};

	removeData = () =>{
		const clinic = this.state?.memberClinics.filter(item => item.groupId != this.state?.selectedClinicForm?.groupId);
		this.setState({
			memberClinics:  clinic,
			selectedClinicForm: clinic.length ? structuredClone(clinic[0]) : undefined,
			selectedClinicIndex: clinic.length ? 0 : undefined,
		});
	};
	
	render() {
		// Return what you want displayed while fetching data here
		if (this.state === undefined || this.state === null) {
			return <></>;
		}

		// If the user is not assigned to a clinic, show the empty page
		if (this.state.memberClinics.length === 0) {
			// Update user_type to reflect the kind of user that is logged in.
			// This is being done at the parent level to avoid having to pass
			// additional props to the child component.
			const UPDATED_EMPTY_MEMBERS = {...EMPTY_MEMBERS, user_type: "admin"};
			pushDataLayerEvent(UPDATED_EMPTY_MEMBERS);

			return <Empty/>;
		}

		// Display the application here
		return [
			React.cloneElement(transformExportedDesign({
				tabs: <Tabs
					value={this.state?.selectedTab ?? 0}
					onChange={(_: React.SyntheticEvent, v: number) => {
						this.setState({
							selectedTab: v,
						});
					}}
				/>,
				tab: <Tab disabled={Boolean(this.state?.dirty)} />,
				tab1: <Tab style={{display:(isMemberAdmin(this.state?.selectedClinicForm?.permissions))? "block" : "none"}} disabled={!isMemberAdmin(this.state?.selectedClinicForm?.permissions)} />,
				"text-field1on-white1single-linewith-icona-inactive-copy": <SelectW
					disabled={Boolean(this.state?.dirty)}
					skeleton={!this.state?.memberClinics}
					label={this.props.t("exported.Selected Team")}
					value={() => this.state?.selectedClinicIndex ?? "You are not assigned to any group"}
					items={this.state?.memberClinics?.map((c, i) => ({
						value: i,
						label: (c.relationshipData.nickname ?? c.groupData.clinicName) as string,
					}))}
					onChange={v => {
						this.setState({
							selectedClinicForm: structuredClone(this.state?.memberClinics[Number(v)]),
							selectedClinicIndex: Number(v),
							selectedTab: isMemberAdmin(this.state?.memberClinics[Number(v)].permissions) ? this.state.selectedTab : 0,
						});
					}}
				/>,
				// 'frame-button2': <Button disabled={Boolean(this.state?.dirty)} />,
				"frame-button2": "removeAll",
				frame51: [
					<GroupDetails key={0} clinicInfo={this.state?.selectedClinicForm} user={this.state?.user} onUpdate={this.refreshData} onRemoval={this.removeData} />,
					<GroupMembers key={1} clinicInfo={this.state?.selectedClinicForm} user={this.state?.user} onDirty={this.updateDirty} />,
				][this.state?.selectedTab ?? 0],
				frame334: "removeAll",
			}, P11440311HGMHomeAdminmemberViewgroupdetailstabclon()), {key: 0}), // eslint-disable-line new-cap
			<ModalDialog
				key={1}
				open={this.state?.modalDialog?.open ?? false}
				onClose={this.handleModalDialogClose}
				style={this.state?.modalDialog?.style}
				title={this.state?.modalDialog?.title ?? ""}
				content={this.state?.modalDialog?.content ?? []}
				actions={this.state?.modalDialog?.actions ?? []} />,
		];
	}
}

export default withTranslation()(withRouter(App));

export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};

export type ToastState = {
	open: boolean;
	severity?: AlertColor;
	message?: string;
	errorDetails?: string;
};

export type ModalDialogState = {
	open: boolean;
	title?: string;
	content?: JSX.Element | JSX.Element[];
	actions?: JSX.Element[];
	style?: React.CSSProperties;
};

export const objectMerge = (s: Record<string, unknown>, d: Record<string, unknown>) => {
	Object.keys(s).forEach(k => {
		if (s[k] && typeof s[k] === "object") {
			d[k] = d[k] ?? {};
			objectMerge(s[k] as Record<string, unknown>, d[k] as Record<string, unknown>);
		} else {
			d[k] = s[k];
		}
	});
};
