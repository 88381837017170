import {PureComponent} from 'react';
import {DialogContent, Dialog, DialogTitle, DialogActions, Typography} from '@mui/material';
import '../i18n/config';
import P11440312HGMHomeAdminmemberVieweditgroupnickname from './.exported_teleporthq/groups wip-react/src/views/p11440312h-g-m-home-adminmember-vieweditgroupnickname';
import {transformExportedDesign} from '../muiConverter';

class ModalDialog extends PureComponent<{
	open: boolean;
	onClose: () => void;
	title: string;
	content: JSX.Element | JSX.Element[];
	actions: JSX.Element[];
	style?: React.CSSProperties;
}> {
	render() {
		return transformExportedDesign({
			modal: <Dialog PaperProps={{style: this.props.style}} open={this.props.open} onClose={this.props.onClose} />,
			text094: <DialogTitle><Typography variant='DesktopH2'>{this.props.title}</Typography></DialogTitle>,
			'text-field': <DialogContent style={{paddingTop: '4px'}}>{this.props.content}</DialogContent>,
			group54: <DialogActions style={{padding: '0 24px'}}>{this.props.actions}</DialogActions>,
			cancel: <img onClick={this.props.onClose} style={{cursor: 'pointer'}} />,
			frame24: <div style={{height: 'unset'}} />,
			frame51: 'removeAll',
			frame330: 'removeAll',
			frame334: 'removeAll',
			's-v-g80whitescrim': 'remove',
		}, P11440312HGMHomeAdminmemberVieweditgroupnickname()); // eslint-disable-line new-cap
	}
}

export default ModalDialog;
