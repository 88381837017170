import React from 'react'

import { Helmet } from 'react-helmet'

import './p11440312h-g-m-home-adminmember-vieweditgroupnickname.css'

const P11440312HGMHomeAdminmemberVieweditgroupnickname = (props) => {
  return (
    <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-p11440312h-g-m-home-adminmember-vieweditgroupnickname">
        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-header">
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-primary">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-navigation-logo">
              <img
                src="/playground_assets/logobackground6935-fki6.svg"
                alt="LogoBackground6935"
                className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-logo-background"
              />
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text DesktopH3">
                <span>Vet</span>
              </span>
              <img
                src="/playground_assets/simple6935-rwta.svg"
                alt="Simple6935"
                className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-simple"
              />
            </div>
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-link-items">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text002">
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text003 UIObjectsText3Bold">
                    <span>Products</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6935-osw.svg"
                  alt="directionexpand6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand"
                />
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links1">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text005">
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text006 UIObjectsText3Bold">
                    <span>Ordering</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6935-0sme.svg"
                  alt="directionexpand6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand1"
                />
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links2">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text008">
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text009 UIObjectsText3Bold">
                    <span>Resources</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6935-89qh.svg"
                  alt="directionexpand6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand2"
                />
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links3">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text011">
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text012 UIObjectsText3Bold">
                    <span>Education</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6935-9mnt.svg"
                  alt="directionexpand6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand3"
                />
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links4">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text014">
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text015 UIObjectsText3Bold">
                    <span>Contact</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6935-zmdc.svg"
                  alt="directionexpand6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand4"
                />
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links5">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text017"></div>
              </div>
            </div>
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-utility">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links6">
              <img
                src="/playground_assets/search6935-g0p8.svg"
                alt="search6935"
                className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-search"
              />
            </div>
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links7">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-navigation-links8">
                <img
                  src="/playground_assets/person6935-3h5.svg"
                  alt="person6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-person"
                />
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame1">
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text018 UIObjectsText3Bold">
                  <span>Dan Thompson</span>
                </span>
              </div>
              <img
                src="/playground_assets/directionexpand6935-rccl.svg"
                alt="directionexpand6935"
                className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-directionexpand5"
              />
            </div>
          </div>
        </div>
        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame51">
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-section1">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext">
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text020 DesktopH2">
                <span>Informational Details</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text022 Paragraph1">
                <span>View or update your groups details here.</span>
              </span>
            </div>
          </div>
          <img
            src="/playground_assets/rectanglecopy6935-vi1u-200h.png"
            alt="RectangleCopy6935"
            className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy"
          />
        </div>
        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame334">
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame54">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame511">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext1">
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text024 DesktopH3">
                  <span>Group Official Name</span>
                </span>
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text026 Paragraph1">
                  <span>College Blvd Animal Hospital</span>
                </span>
              </div>
            </div>
            <img
              src="/playground_assets/rectanglecopy6935-eg4k-200h.png"
              alt="RectangleCopy6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy1"
            />
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame541">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame512">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext2">
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text028 DesktopH3">
                  <span>Team Nick Name</span>
                </span>
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text030 Paragraph1">
                  <span>College Campus</span>
                </span>
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-content">
                <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-button">
                  <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-parent-button">
                    <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-internal">
                      <img
                        src="/playground_assets/editi693-khbk.svg"
                        alt="editI693"
                        className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-edit"
                      />
                      <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox">
                        <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text032 UIObjectsButton">
                          <span>Edit</span>
                        </span>
                      </div>
                    </button>
                  </button>
                </button>
              </div>
            </div>
            <img
              src="/playground_assets/rectanglecopy6935-ro2-200h.png"
              alt="RectangleCopy6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy2"
            />
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame513">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-textandsupportingtext3">
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text034 DesktopH3">
                <span>SAP ID # / Hill’s Account Number</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text036 Paragraph1">
                <span>574891759</span>
              </span>
            </div>
            <img
              src="/playground_assets/rectanglecopy6935-ircd-200h.png"
              alt="RectangleCopy6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy3"
            />
          </div>
        </div>
        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame330">
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame329">
            <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text038 DesktopH1">
              <span>Groups Management</span>
            </span>
            <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-button1">
              <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-parent-button1">
                <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-internal1">
                  <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox1">
                    <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text040 UIObjectsButton">
                      <span>Join a Group</span>
                    </span>
                  </div>
                </button>
              </button>
            </button>
          </div>
          <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text042 Paragraph1">
            <span>Select a group to access it’s details below.</span>
          </span>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-field1on-white1single-linewith-icona-inactive-copy">
            <img
              src="/playground_assets/container6935-vb4m-200h.png"
              alt="Container6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-container1"
            />
            <img
              src="/playground_assets/state6935-haw-500w.png"
              alt="State6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-state"
            />
            <img
              src="/playground_assets/focus6935-chw8-500w.png"
              alt="Focus6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-focus"
            />
            <img
              src="/playground_assets/icon6935-ir7m.svg"
              alt="Icon6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-icon"
            />
            <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text044 Paragraph2">
              <span>College Blvd Animal Hospital</span>
            </span>
            <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text046">
              <span>Selected Group</span>
            </span>
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-group50">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tabs">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-scrollable-tab-text-elements">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab">
                  <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-content">
                    <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text048 UIObjectsText3BOLDCAPS-2">
                      <span>Group Details</span>
                    </span>
                  </div>
                  <img
                    src="/playground_assets/tabbar6935-3jyn-200h.png"
                    alt="TabBar6935"
                    className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-bar"
                  />
                </div>
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-scrollable-tab-text-elements1">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab1">
                  <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-content1">
                    <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text050 UIObjectsText3BOLDCAPS-2">
                      <span>Members</span>
                    </span>
                  </div>
                  <img
                    src="/playground_assets/tabbar6935-kq8-200h.png"
                    alt="TabBar6935"
                    className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-tab-bar1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-footer">
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-framebase-footer-legal">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-utility-links">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame83">
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text052 UIObjectsText4">
                  <span>Copyright © 2022 Hill’s. All rights reserved.</span>
                </span>
                <img
                  src="/playground_assets/rectangle6935-yd0i-200w.png"
                  alt="Rectangle6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle"
                />
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text054 UIObjectsText4">
                  <span>Legal &amp; Privacy Policy</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy236935-7yz-200w.png"
                  alt="RectangleCopy236935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy23"
                />
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text056 UIObjectsText4">
                  <span>Terms &amp; Conditions</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy236935-ltaj-200w.png"
                  alt="RectangleCopy236935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy231"
                />
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text058 UIObjectsText4">
                  <span>Do Not Sell My Personal Information</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy236935-o6yw-200w.png"
                  alt="RectangleCopy236935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy232"
                />
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text060 UIObjectsText4">
                  <span>Site Map</span>
                </span>
                <img
                  src="/playground_assets/rectanglecopy236935-bnzr-200w.png"
                  alt="RectangleCopy236935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-rectangle-copy233"
                />
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text062 UIObjectsText4">
                  <span>Cookie Consent Tool</span>
                </span>
              </div>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame326">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame831">
                  <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-country">
                    <img
                      src="/playground_assets/vectori693-9c3b.svg"
                      alt="vectorI693"
                      className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-vector"
                    />
                  </div>
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text064 UIObjectsText4">
                    <span>Country [English]</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-horizon">
            <img
              src="/playground_assets/brandhorizon6935-4fkg.svg"
              alt="BrandHorizon6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-brand-horizon"
            />
            <img
              src="/playground_assets/brandhorizon6935-00ne.svg"
              alt="BrandHorizon6935"
              className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-brand-horizon1"
            />
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-connect-with-us">
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text066 UIObjectsText3Bold">
                <span>Connect With Us</span>
              </span>
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-social-icons">
                <img
                  src="/playground_assets/facebook6935-9q45.svg"
                  alt="Facebook6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-facebook"
                />
                <img
                  src="/playground_assets/twitter6935-30hs.svg"
                  alt="Twitter6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-twitter"
                />
                <img
                  src="/playground_assets/facebook6935-kv2r.svg"
                  alt="Facebook6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-facebook1"
                />
                <img
                  src="/playground_assets/youtube6935-a4iy.svg"
                  alt="youtube6935"
                  className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-youtube"
                />
              </div>
            </div>
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame9">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-about-hills">
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text068 UIObjectsText3Bold">
                <span>About Hill’s</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text070 UIObjectsText3">
                <span>Our Company</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text072 UIObjectsText3">
                <span>Hill’s Pet</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text074 UIObjectsText3">
                <span>Careers</span>
              </span>
            </div>
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-our-food-brands">
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text076 UIObjectsText3Bold">
                <span>Our Food Brands</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text078 UIObjectsText3">
                <span>Hill’s Retail Order</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text080 UIObjectsText3">
                <span>VIP Market</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text082 UIObjectsText3">
                <span>Hill’s to Home</span>
              </span>
              <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text084 UIObjectsText3">
                <span>Download Order Forms</span>
              </span>
            </div>
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-support">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame327">
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text086 UIObjectsText3Bold">
                  <span>Support</span>
                </span>
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text088 UIObjectsText3">
                  <span>Contact Us</span>
                </span>
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text090 UIObjectsText3">
                  <span>Manage Your Profile</span>
                </span>
                <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text092 UIObjectsText3">
                  <span>Clinic Application</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/playground_assets/svg80whitescrim6935-409nn.svg"
          alt="SVG80whitescrim6935"
          className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-s-v-g80whitescrim"
        />
        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal">
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal1">
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal-window">
              <img
                src="/playground_assets/modalsurfacei693-znyu-400h.png"
                alt="ModalSurfaceI693"
                className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-modal-surface"
              />
              <img
                src="/playground_assets/canceli693-r2vg.svg"
                alt="cancelI693"
                className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-cancel"
              />
            </div>
          </div>
          <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame24">
            <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text094 DesktopH2">
              <span>Edit your Team Nick Name</span>
            </span>
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-field">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-base-text-field">
                <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-field-all">
                  <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-containerfor-focus-state">
                    <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-containerw-inner-textboxes">
                      <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text-box-group">
                        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox2">
                          <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text096 UIObjectsText4">
                            <span>Team Nick Name</span>
                          </span>
                        </div>
                        <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox3">
                          <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text098 Paragraph2">
                            <span>College Blvd Animal Hospital</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-group54">
              <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame27">
                <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-masters1button">
                  <img
                    src="/playground_assets/container6935-jywj-200h.png"
                    alt="Container6935"
                    className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-container2"
                  />
                  <img
                    src="/playground_assets/state6935-2yec-200h.png"
                    alt="State6935"
                    className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-state1"
                  />
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text100 UIObjectsButton">
                    <span>Cancel</span>
                  </span>
                  <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text102 UIObjectsButton">
                    <span>Cancel</span>
                  </span>
                  <img
                    src="/playground_assets/focus6935-w4-200h.png"
                    alt="Focus6935"
                    className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-focus1"
                  />
                </button>
                <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frame-button2">
                  <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-parent-button2">
                    <button className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-button-internal2">
                      <div className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-frametextbox4">
                        <span className="p11440312h-g-m-home-adminmember-vieweditgroupnickname-text104 UIObjectsButton">
                          <span>Save</span>
                        </span>
                      </div>
                    </button>
                  </button>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default P11440312HGMHomeAdminmemberVieweditgroupnickname
