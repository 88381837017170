import i18n, {type Resource} from 'i18next';
import * as appLocales from '.';
import * as coreLocales from '@mui/material/locale';
import * as gridLocales from '@mui/x-data-grid';
import {initReactI18next} from 'react-i18next';

export const locale = 'enUS';

export const coreLocale = coreLocales[locale];
export const gridLocale = gridLocales[locale];

void i18n.use(initReactI18next).init({
	lng: locale,
	interpolation: {escapeValue: false},
	resources: Object.entries(appLocales).reduce<Resource>((p, [k, v]) => {
		p[k] = {translation: v};
		return p;
	}, {}),
});
